.facilities-page{
  & .title-block{
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 40px;
    &:before{
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      height: 5px; width: 100px;
      background-color: $color1;
    }
    & h2{
      font-size: 36px;
      font-weight: 400;
      @media(max-width: 767px){
        margin-top: 20px;
        font-size: 21px;
      }
    }
    & .location{
      display: block;
      font-family: $font-family2;
      font-size: 14px;
      color: $color1;
      margin-top: 10px;
    }
  }
  & .facilities-description{
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
  }
  & .go-to-link{
    font-family: $font-family2;
    font-weight: 400;
    font-size: 16px;
    color: $color1;
    & i{
      font-size: 10px;
      display: inline-block;
      vertical-align: top;
      line-height: 20px;
    }
  }
  & .star-rate{
    height: 17px;
    margin: 20px 0;
    & .star{
      display: inline-block;
      float: left;
      margin-right: 8px;
      width: 17px; height: 17px;
      background: url("../images/star.png") no-repeat center;
    }
  }
  & .facilities-item{
    &:last-of-type{
      margin-bottom: 0;
    }
    margin-bottom: 100px;
    @media(min-width: 768px) and (max-width: 1199px){
      margin-bottom: 60px;
    }
    @media(max-width: 767px){
      margin-bottom: 40px;
    }
  }
}