// ***********************************
// SEARCH
// ***********************************
.sb-search-positioning{
  position: absolute;
  right: 0; top: 0;
  width: 50px; height: 40px;
  display: inline-block;
}
.sb-search {
  position: absolute;
  right: 0;
  width: 0%;
  min-width: 50px;
  height: 40px;
  float: right;
  overflow: hidden;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  transition: width 0.4s;
  -webkit-backface-visibility: hidden;
  border: 1px solid $black;
}

.sb-search-input {
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  border: 0 none;
  background: $white;
  width: 100%;
  height: 40px;
  margin: 0;
  z-index: 1;
  padding: 0px 12px 0px 20px;
  font-family: inherit;
  font-size: 14px;
  color: $black;
}

.sb-search-input::-webkit-input-placeholder {
  color: $black;
}

.sb-search-input:-moz-placeholder {
  color: $black;
}

.sb-search-input::-moz-placeholder {
  color: $black;
}

.sb-search-input:-ms-input-placeholder {
  color: $black;
}

.sb-icon-search,
.sb-search-submit  {
  width: 50px;
  height: 40px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.sb-search-submit {
  background: $color1; /* IE needs this */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
  filter: alpha(opacity=0); /* IE 5-7 */
  opacity: 0;
  border: none;
  outline: none;
  z-index: -1;
  color: $black;
}

.sb-icon-search {
  color: $black;
  background: $white;
  z-index: 1;
  font-size: 18px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  line-height: 38px;
}

.sb-icon-search:before {
  content: "\f002";
}

/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
  width: 300px;
  @media(max-width: 600px){
    width: 200px;
    z-index: 3;
  }
}

.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {
  background: $color1;
  color: $white;
  z-index: 1;
}

.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
  z-index: 2;
  position: absolute;
}

/*AUTOCOMPLETE INPUT*/
.ui-widget-content {
  border: 1px solid $black;
  background-color: $white;
}
.ui-menu .ui-menu-item {
  padding: 5px 10px;
  cursor: pointer;
  border: 0 none;
  color: $black;
  font-size: 12px;
  margin: 0;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border-bottom: 0;
  background: $black;
  color: $white;
  font-weight: normal;
  margin: 0;
}


//SEARCH PAGE
.search-form-holder{
  max-width: 400px;
  padding-right: 50px;
  position: relative;
  border: 1px solid $black;
  margin: 40px 0 30px 0;
  & button{
    position: absolute; right: 0; top: 0;
    width: 50px; height: 40px;
    color: $white;
    background-color: $color1;
    border: 0;
    box-shadow: none; border-radius: 0;
    font-size: 18px;
  }
  & input{
    height: 40px;
    line-height: 20px;
    padding: 10px 20px;
    border: 0;
    margin: 0;
    color: black;
    &::-webkit-input-placeholder {
      color: black;
      font-family: $font-family1;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: black;
      font-family: $font-family1;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      color: black;
      font-family: $font-family1;
    }

    &:-ms-input-placeholder {
      color: black;
      font-family: $font-family1;
    }
  }
}
.search-results{
  font-family: $font-family2;
  font-weight: 300;
  padding-top:5px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 50px;
  & li{
    position: relative;
    margin-bottom: 5px;
    &:before{
      content: "";
      position: absolute;
      top: 5px; left: -20px;
      width: 10px; height: 10px;
      background-color: $color1;
    }
    &:hover{
      color: $color1;
    }
    i{
      font-weight: 300;
    }
  }
}