.accordion-toggle {
  position: relative;
  padding-left: 30px;
}
.accordion-toggle::before,
.accordion-toggle::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  width: 14px;
  height: 4px;
  margin-top: -2px;
  background-color: #000;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.accordion-toggle::before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  opacity: 0;
}
.accordion-toggle.collapsed::before {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1;
}
.accordion-toggle.collapsed::after {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.faq-accordion{

  //ponistavanje stilova bootstrap
  & .panel-group{
    margin-bottom: 0;
    & .panel{
      border: 0 none;
      box-shadow: none;
    }
  }
  & .panel-default>.panel-heading {
    color: $black;
    background-color: transparent;
    border: 0 none;
  }
  .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top: 0 none;
  }

  & .panel-heading {
    padding: 5px 0;
  }
  & .panel-body {
    padding: 5px 0;
  }
  & .panel-title a{
    font-size: 20px;
    display: block;
  }

}

.used-discounts-accordion{
  margin-top: 50px;
  font-family: $font-family3;
  text-transform: uppercase;

  //ponistavanje stilova bootstrap
  & .panel-group{
    margin-bottom: 0;
    & .panel{
      border: 0 none;
      box-shadow: none;
    }
  }


  .accordion-toggle {
    padding-right: 30px;
    padding-left: 0;
  }
  .accordion-toggle::before,
  .accordion-toggle::after {
    right: 0px; left: auto;
    background-color: $white;
  }


  & .panel-default>.panel-heading {
    color: $white;
    background-color: $black;
    border: 0 none;
    border-radius: 0;
    font-weight: 700;
  }
  .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top: 0 none;
  }

  & .panel-heading {
    padding: 10px 15px;
  }
  & .panel-body {
    padding: 15px 0;
  }
  & .panel-title a{
    font-size: 14px;
    display: block;
  }

  table{
    width: 100%;
    font-family: $font-family3;
    table-layout: fixed;
    & tr{
      border-bottom: 1px solid #ddd;
    }
    & th{
      padding: 10px;
      font-weight: 400;
    }
    & td{
      padding: 15px 10px;
      font-weight: 700;
    }
    & tr > th:last-of-type,
    & tr > td:last-of-type{
      text-align: right;
    }
  }

}