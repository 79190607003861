.form{
  padding: 0 15px;
}

input{
  display: block;
  width: 100%;
  border: 1px solid $grey;
  border-width: 0 0 1px 0;
  line-height: 20px;
  padding: 4px 10px;
  font-family: $font-family2;
  font-weight: 300;
  font-size: 16px;
  margin-top: 10px;
}

textarea{
  display: block;
  width: 100%;
  border: 1px solid $color1;
  line-height: 20px;
  padding: 14px 20px 4px 20px;
  font-family: $font-family2;
  font-weight: 300;
  font-size: 16px;
  margin-top: 20px;
  min-height: 100px;
}

.fake-placeholder {
  position: absolute;
  line-height: 20px;
  padding: 4px 10px;
  height: 30px;
  z-index: 1;
  & span{
    position: relative;
    display: inline-block;
    padding-right: 15px;
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
  }
  &.mandatory span:after{
    position: absolute;
    top: -5px;
    right: 2px;
    font-family: FontAwesome;
    content: "\f069";
    font-size: 8px;
    color: $color1;
  }
  &.contact-message{
    padding: 14px 20px 4px 20px;
  }
}

.btn-t1{
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 25px;
  padding: 15px 25px;
  background-color: $yellow;
  float: right;
  border: 0;
  font-family: $font-family1;
  @include transition-all(0.4s);
  &:hover{
    background-color: $black;
    color: $white;
  }
  &.fl-none{
    float: none;
  }
}

.btn-ghost{
  background-color: transparent;
  border: 1px solid $color1;
  border-radius: 0;
  text-align: center;
  color: $color1;
  line-height: 20px;
  padding: 4px 15px;
  display: inline-block;
  @include transition-all(0.4s);
  &:hover{
    background-color: $color1;
    color: $white;
  }
}
a.btn-ghost{
  color: $color1;
}


/*fake checkbox*/
.fake-checkbox label{position: relative; line-height: 20px; padding: 5px 50px 5px 10px; display: block; text-align: left; font-family: $font-family2; font-weight: 300; font-size: 16px; cursor: pointer; margin-bottom: 0;}
.fake-checkbox label:before{content: ""; position: absolute; right: 0px; bottom: 0; width: 40px; height: 30px; border: 1px solid $color1;}
.fake-checkbox label:after{content: "x"; font-family: $font-family2; font-weight: 300; font-size: 32px; line-height: 30px; width: 40px; text-align: center; color: $black; position: absolute; right: 0px; bottom: 3px; display: none;}
.fake-checkbox label.active:after{display: inline-block;}

.notifications{
  & .fake-checkbox{
    height: 70px;
    margin-bottom: 20px;
    position: relative;
    & label{
      top: auto;
      padding-top: 0;
      text-align: left;
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
    }
  }
}

.message{
  font-size: 16px;
  color: $color1;
  clear: both;
  text-transform: uppercase;
  & p{
    position: relative;
    line-height: 20px;
    margin-bottom: 20px;
  }
  & .success{
    color: $yellow;
  }
  @media(max-width: 992px){
    font-size: 14px;
  }
}

.captcha-button-block{
  clear: both;
  & .captcha-holder{
    padding: 10px 0 20px 0;
    & .captcha-text{
      font-family: $font-family3;
      margin-bottom: 5px;
      display: table;
      height: 40px;
      & span{
        display: table-cell;
        line-height: 20px;
        vertical-align: middle;
      }
    }
  }
  & .chapta_box{
    position: relative;
    padding-left: 130px;
    & img{
      position: absolute;
      left: 0; top: 0;
      height: 40px; width: 120px;
    }
    & input{
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 9px 10px;
      border: 1px solid #ddd;
      text-align: center;
      color: #666;
    }
  }
}

.password-forgotten{
  display: block;
  line-height: 25px;
  margin-top: 15px; margin-bottom: 15px;
  text-decoration: underline;
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  font-family: $font-family3;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
  &:before{
    content: "\f069";
    font-family: FontAwesome;
    position: absolute;
    left: 5px; top: -5px;
    font-size: 10px;
  }
}