//LATEST NEWS
.latest-news{
  background-color: $grey;
  & h2.section-title{
    margin-bottom: 60px;
    & span{
      background-color: $grey;
    }
    @media(max-width: 767px){
      margin-bottom: 30px;
    }
  }
}
.latest-news-holder{
  & > li{
    position: relative;
    & .overlay{
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
    }
    & .content-holder{
      height: 200px;
      position: relative;
      padding-left: 50%;
      background-color: $white;
      @media(max-width: 767px){
        height: auto; min-height: 400px;
        padding-left: 0;
        padding-top: 200px;
      }
    }
    & .image-holder{
      position: absolute;
      top: 0; left: 0;
      width: 50%; height: 100%;
      & .img-overlay{
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background-color: rgba(85,85,85,0.3);
      }
      @media(max-width: 767px){
        width: 100%; height: 200px;
        top: 0; left: 0; right: 0;
      }
    }
    &:nth-of-type(even){
      & .content-holder{
        padding-right: 50%;
        padding-left: 0;
        @media(max-width: 767px){
          height: auto; min-height: 400px;
          padding-left: 0; padding-right: 0;
          padding-top: 200px;
        }
      }
      & .image-holder{
        position: absolute;
        left: auto; right: 0;
        @media(max-width: 767px){
          width: 100%; height: 200px;
          top: 0; left: 0; right: 0;
        }
      }
    }
    & .text-holder{
      padding: 15px 20px;
      & .date{
        font-family: $font-family2;
        font-size: 12px;
      }
      & h3{
        font-weight: 400;
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 10px;
        @include transition-all(0.4s);
      }
      & p{
        font-family: $font-family2;
        font-weight: 300;
        margin-bottom: 0;
        @include transition-all(0.4s);
      }
    }
    &:hover{
      & .text-holder{
        & h3{
          color: $color1;
        }
        & p{
          color: $color1;
        }
      }
    }
  }
}

//NEWS LIST
.news-main{
  background-color: $grey;
  margin-bottom: 20px;
  & .image-holder,
  & .text-holder{
    height: 450px;
    @media(min-width: 768px) and (max-width: 991px){
      height: 350px;
    }
  }
  & .image-holder{
    @media(min-width: 768px) and(max-width: 991px){
      margin-right: -10px;
    }
    @media(max-width: 767px){
      height: 300px;
    }
  }
  & .text-holder{
    text-align: center;
    padding: 30px 20px 30px 0px;
    & p{
      font-family: $font-family2;
      font-weight: 300;
    }
    @media(max-width: 767px){
      height: auto;
      padding: 20px 10px 20px 10px;
    }
  }

  & .title-block{
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media(max-width: 767px){
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    &:before{
      content: '';
      position: absolute;
      left: 50%; bottom: 0;
      height: 5px; width: 100px;
      margin-left: -50px;
      background-color: $color1;
    }
    & h2{
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 400;
      @media(max-width: 767px){
        font-size: 21px;
      }
    }
    & .location{
      text-transform: uppercase;
      display: block;
      font-family: $font-family2;
      font-size: 18px;
      color: $color1;
      margin-top: 10px;
      @media(max-width: 767px){
        font-size: 16px;
        margin-top: 5px;
      }
    }
  }
  & .read-more{
    padding: 9px 15px;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
    width: 170px;
    @media(min-width: 768px){
      position: absolute;
      bottom: 30px; left: 50%;
      margin-left: -90px;
    }
  }
}


.news-list-holder{
  & > div{
    position: relative;
    & .overlay{
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
    }
    & .content-holder{
      height: 200px;
      position: relative;
      padding-left: 50%;
      background-color: $white;
      @media(max-width: 767px){
        height: auto; min-height: 400px;
        padding-left: 0;
        padding-top: 200px;
      }
    }
    & .image-holder{
      position: absolute;
      top: 0; left: 0;
      width: 50%; height: 100%;
      & .img-overlay{
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background-color: rgba(85,85,85,0.3);
      }
      @media(max-width: 767px){
        width: 100%; height: 200px;
        top: 0; left: 0; right: 0;
      }
    }
    &:nth-of-type(even){
      & .content-holder{
        padding-right: 50%;
        padding-left: 0;
        @media(max-width: 767px){
          height: auto; min-height: 400px;
          padding-left: 0; padding-right: 0;
          padding-top: 200px;
        }
      }
      & .image-holder{
        position: absolute;
        left: auto; right: 0;
        @media(max-width: 767px){
          width: 100%; height: 200px;
          top: 0; left: 0; right: 0;
        }
      }
    }
    & .text-holder{
      padding: 15px 20px;
      & .date{
        font-family: $font-family2;
        font-size: 12px;
      }
      & h3{
        font-weight: 400;
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 10px;
        @include transition-all(0.4s);
      }
      & p{
        font-family: $font-family2;
        font-weight: 300;
        margin-bottom: 0;
        @include transition-all(0.4s);
      }
    }
    &:hover{
      & .text-holder{
        & h3{
          color: $color1;
        }
        & p{
          color: $color1;
        }
      }
    }
  }
}



//NEWS PAGE
.news-page{
  & .news-image{
    width: 100%;
  }
  & .title-block{
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 20px;
    &:before{
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      height: 5px; width: 100px;
      background-color: $color1;
    }
    & h1{
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 400;
    }
    & .location{
      text-transform: uppercase;
      display: block;
      font-family: $font-family2;
      font-size: 18px;
      color: $color1;
      margin-top: 10px;
    }
  }
  & .news-date{
    font-family: $font-family2;
    font-size: 12px;
  }
  & .expand-photo{
    padding: 9px 15px;
    text-transform: uppercase;
    margin-top: 50px;
    cursor: pointer;
    @media(max-width: 767px){
      display: none;
    }
  }

  & .text-editor{
    margin: 40px 0;
  }

  & .social-links{
    margin-bottom: 50px;
  }
}