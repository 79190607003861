// ***********************************
// VARIABLES
// ***********************************

// TYPOGRAPHY
$font-family1: 'Montserrat', sans-serif;
$font-family2: 'Open Sans', sans-serif;
$font-family3: 'Open Sans', sans-serif;

// COLORS
$white: #fff;
$black: #333;
$grey: #ddd;
$yellow: #ffcc00;
$gold: #D4AE37;

$color1: $gold;
