// ***********************************
// BREADCRUMBS
// ***********************************
.breadcrubms{margin: 30px 0 40px 0; font-family: $font-family2;}
.breadcrubms ul{margin:0; padding:0; list-style:none;}
.breadcrubms ul li{ float: none; display:inline-block;}
.breadcrubms ul li:after{
  content: "";
  position: absolute;
  right: 7px; top: 4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 5px;
  border-color: transparent transparent transparent $color1;
}
.breadcrubms ul li:last-of-type:after{display: none;}
.breadcrubms ul li:first-child{padding-left:0;}
.breadcrubms ul li,
.breadcrubms ul li a{ text-decoration:none; cursor: pointer; position: relative; }
.breadcrubms ul li a{ padding-right: 20px;}
.breadcrubms ul li:last-of-type a{padding-right: 0}
.breadcrubms ul li a:hover{}
.breadcrubms ul li.current,
.breadcrubms ul li.current a{cursor: default; text-decoration: none; pointer-events: none;}


// ***********************************
// BREADCRUMBS ADDITIONAL
// ***********************************
.breadcrumbs-additional{margin: 30px 0 40px 0; font-size: 24px;}
.breadcrumbs-additional ul{margin:0; padding:0; list-style:none;}
.breadcrumbs-additional ul li{ float: none; display:inline-block;}
.breadcrumbs-additional ul li:after{
  content: "";
  position: absolute;
  right: 8px; top: 8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 8px;
  border-color: transparent transparent transparent $color1;
}
.breadcrumbs-additional ul li:last-of-type:after{display: none;}
.breadcrumbs-additional ul li:first-child{padding-left:0;}
.breadcrumbs-additional ul li,
.breadcrumbs-additional ul li a{ text-decoration:none; cursor: pointer; position: relative; }
.breadcrumbs-additional ul li a{ padding-right: 30px;}
.breadcrumbs-additional ul li:last-of-type a{padding-right: 0}
.breadcrumbs-additional ul li a:hover{}
.breadcrumbs-additional ul li.current,
.breadcrumbs-additional ul li.current a{cursor: default; text-decoration: none; pointer-events: none;}

