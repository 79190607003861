.banner-t1{
  & .banner-holder{
    position: relative;
    padding: 30px 0 20px 0;
    font-family: $font-family2;
    font-weight: 300;
    font-size: 18px;
    color: $white;
    text-align: center;
    & p{

    }
  }
  & .overlay{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(75,58,40,0.9);
  }
  & .overlay-texture{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-image: url('../images/overlay-texture.png');
  }
  & .line{
    display: block;
    width: 240px; height: 5px;
    background-color: $color1;
    margin: 0 auto;
    margin-bottom: 15px;
    position: relative;
    z-index: 0;
  }
  @media(min-width: 481px) and (max-width: 767px){
    & .banner-holder{
      padding: 30px 0 20px 0;
      font-size: 22px;
    }
    & .line{
      margin-bottom: 15px;
    }
  }
  @media(min-width: 767px) and (max-width: 991px){
    & .banner-holder{
      padding: 40px 0 50px 0;
      font-size: 30px;
    }
    & .line{
      margin-bottom: 20px;
    }
  }
  @media(min-width: 992px) and (max-width: 1199px){
    & .banner-holder{
      padding: 60px 0 80px 0;
      font-size: 36px;
    }
    & .line{
      margin-bottom: 30px;
    }
  }
  @media(min-width: 1200px){
    & .banner-holder{
      padding: 100px 0 120px 0;
      font-size: 42px;
    }
    & .line{
      margin-bottom: 40px;
    }
  }
}

.banner-t2{
  margin-top: 50px;
  margin-bottom: 50px;
  & table{
    width: auto!important;
    margin-top: 30px;
    & tr{
      & > td{
        padding-left: 50px;
        position: relative;
        line-height: 40px;
        font-family: $font-family2;
        font-weight: 300;
        font-size: 16px;
        &:before{
          content: "\f095";
          font-family: FontAwesome;
          font-size: 18px;
          color: $color1;
          position: absolute;
          left: 0; top: 0;
          width: 42px; height: 40px; line-height: 40px;
          text-align: center;
        }
        &:after{
          content: "";
          position: absolute;
          left: 0; top: 0;
          width: 40px; height: 40px;
          border: 1px solid $color1;
          border-radius: 50%;
        }
        &:first-of-type{
          padding-right: 40px;
        }
        &:last-of-type:before{
          content: "\f0e0";
          font-size: 18px;
        }
      }
    }
  }
}