@import "reset";


/* MAIN CSS */
//@import "basic-css/bootstrap.min.scss";
//@import "basic-css/jquery-ui.min.scss";
//@import "basic-css/animate.scss";
//@import "basic-css/hover-min.scss";

/* FONTS*/
@import "../bower_components/font-awesome/css/font-awesome.min.css";

/* PLUGIN CSS */
@import "../no_bower_components/bootstrap/css/bootstrap.min.css";
//@import "../bower_components/OwlCarouselBower/owl-carousel/owl.carousel.css";
//@import "../bower_components/OwlCarouselBower/owl-carousel/owl.theme.css";
//@import "../bower_components/OwlCarouselBower/owl-carousel/owl.transitions.css";
@import "../bower_components/owl.carousel/dist/assets/owl.carousel.min.css";
//@import "../bower_components/bxslider-4/dist/jquery.bxslider.min.css";
@import "../bower_components/animate.css/animate.min.css";
@import "../bower_components/filament-tablesaw/dist/stackonly/tablesaw.stackonly.css";
//@import "../no_bower_components/glasscase/glasscase_production/css/glasscase.min.css";

// ***********************************
// HELPERS
// ***********************************
@import "variables";
@import "mixins";


// ***********************************
// BASE STYLES
// ***********************************
@import "base";


// ***********************************
// UI ELEMENTS
// ***********************************
@import "ui/sliders";
@import "ui/search";
@import "ui/tabs";
@import "ui/forms";
@import "ui/breadcrums";
@import "ui/selectbox";
@import "ui/pagination";
@import "ui/accordion";
@import "ui/tables";
@import "ui/glasscase";
@import "ui/glasscase";
@import "ui/royalslider";
@import "ui/datepicker";


// ***********************************
// SECTIONS
// ***********************************
@import "sections/header";
@import "sections/navigation";
@import "sections/recommended_tours";
@import "sections/top_destinations";
@import "sections/destinations_and_tours";
@import "sections/facilities";
@import "sections/about_us";
@import "sections/banners";
@import "sections/news";
@import "sections/map";
@import "sections/newsletter_form";
@import "sections/simple_page";
@import "sections/contact";
@import "sections/error";
@import "sections/footer";