// ***********************************
// PAGINATION
// ***********************************

/* Pagination */
ul.pagination-list{
  padding: 30px 0;
  display: block;
  text-align: center;
}
ul.pagination-list li{
  display: inline-block;
  width: 26px; height: 26px;
  vertical-align: top;
}
ul.pagination-list li a{
  font-size: 24px;
  font-weight: 300;
  font-family: $font-family2;
  display: block;
  line-height: 24px; width: 100%;
  border: none;
  text-align: center;
  color: inherit;
  position: relative;
  cursor: pointer;
}
ul.pagination-list li a.disabled{
  cursor: default;
  pointer-events: none;
}
ul.pagination-list li a:hover{
  color: $color1;
  text-decoration: none;
}
ul.pagination-list li a.current{
  cursor: default;
  pointer-events: none;
  color: $color1;
  text-decoration: none;
}
ul.pagination-list li:last-of-type{
  margin: 0;
}
@media(max-width: 480px){
  ul.pagination-list  li.hidden-on-mob{
    display: none;
  }
}

ul.pagination-list li.prev{
  width: 35px; height: 60px;
  background-image: url('../images/custom-arr-pagination.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  position: relative;
  top: -15px;
  & a{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
  }
}
ul.pagination-list li.next{
  width: 35px; height: 60px;
  background-image: url('../images/custom-arr-pagination.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  position: relative;
  top: -15px;
  & a{
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
  }
}
ul.pagination-list li.dots{
  letter-spacing: 5px;
  position: relative;
  top: 10px;
}