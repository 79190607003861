/* Background */
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
  background: $white;
}

.royalSlider:not(.rsFullscreen) .rsSlide > img{
  width: 100%!important;
  height: auto!important;
  margin-left: 0px!important; margin-right: 0px!important;
}

/***************
*
*  3. Thumbnails
*
****************/

.rsDefault .rsThumbsHor {
  width: 100%;
  height: 110px;
  padding: 10px 0;
}
.rsDefault .rsThumb {
  float: left;
  overflow: hidden;
  width: 135px;
  height: 90px;
}
.rsDefault .rsThumb img {
  width: 100%;
  height: 100%;
}
.rsDefault .rsThumb.rsNavSelected {
  background: $yellow;
}

/* Thumbnails arrow icons */
.rsDefault .rsThumbsArrow {
  background: rgba(255,255,255,0.5);
  color: #868686;
  &.rsThumbsArrowDisabled{
    display: block!important;
  }
  &:before{
    content: "\f105";
    font-family: FontAwesome;
    font-size: 26px;
    position: absolute;
    left: 5px; top: 50%;
    line-height: 30px;
    margin-top: -15px;
  }
  &.rsThumbsArrowLeft:before{
    content: "\f104";
  }
}
.rsDefault .rsThumbsArrow:hover {
  background: rgba(255,255,255,0.5);
  color: $black;
}
.rsDefault .rsThumbsArrowIcn{
  background: none;
}
@media(max-width: 600px){
  .rsOverflow{
//    height: 100%!important;
  }
}



/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 767px) {
  .rsDefault .rsThumbsHor {
    height: 80px;
  }
  .rsDefault .rsThumb {
    width: 90px;
    height: 60px;
  }
}