.tokens-table{
  text-transform: uppercase;
  width: 100%;
  font-family: $font-family3;
  table-layout: fixed;
  & tr{
    border-bottom: 1px solid #ddd;
  }
  & th{
    padding: 10px;
  }
  & td{
    padding: 15px 10px;
    font-weight: 700;
  }
  & tr > th:last-of-type,
  & tr > td:last-of-type{
    width: 70px;
    text-align: right;
    & a{
      display: inline-block;
      width: 20px;
      position: relative;
      text-align: center;

      & .token-option-tip {
        bottom: 22px;
        left: 50%;
        line-height: 100%;
        margin-left: -35px;
        padding: 0;
        height: 0;
        position: absolute;
        text-align: center;
        white-space: nowrap;
        width: 70px;
        opacity: 0;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        @include transition-all(0.4s);
        &:before{
          content: '';
          position: absolute;
          left: 50%; top: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 10px 0 10px;
          border-color: #eeeeee transparent transparent transparent;
          margin-left: -10px;
        }
      }

      &:hover .token-option-tip {
        height: 32px;
        padding: 11px 0;
        opacity: 1;
        background-color: #eee;
      }

    }
  }

  & .time{
    background-color: $black;
    color: $white;
    padding: 5px 10px;
    margin-left: 3px;
    display: inline-block;
  }
  & .expired{
    background-color: $yellow;
    color: $black;
    padding: 5px 10px;
  }
}

/* Mobile first styles: Begin with the stacked presentation at narrow widths */

@media only all {

  /* Make the label elements a percentage width */

  .tablesaw-stack td .tablesaw-cell-label,
  .tablesaw-stack th .tablesaw-cell-label {
    display: block;
    padding: 0 .6em 0 0;
    width: 40%;
    display: inline-block;
  }
}

@media (max-width: 39.9375em){
  .tokens-table{
    width: 100%;
    font-family: $font-family3;
    table-layout: fixed;
    & tr{
      padding: 10px 0;
    }
    & td{
      padding: 10px 0;
    }
    & tr > th:last-of-type,
    & tr > td:last-of-type{
      width: 100%;
      text-align: left;
      & a{
        display: inline-block;
        width: 20px;
        position: relative;
        text-align: center;

        & .token-option-tip {
          bottom: 22px;
          left: 50%;
          line-height: 100%;
          margin-left: -35px;
          padding: 0;
          height: 0;
          position: absolute;
          text-align: center;
          white-space: nowrap;
          width: 70px;
          opacity: 0;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 700;
          @include transition-all(0.4s);
          &:before{
            content: '';
            position: absolute;
            left: 50%; top: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 10px 0 10px;
            border-color: #eeeeee transparent transparent transparent;
            margin-left: -10px;
          }
        }

        &:hover .token-option-tip {
          height: 32px;
          padding: 11px 0;
          opacity: 1;
          background-color: #eee;
        }

      }
    }
  }
  .tablesaw-cell-content {
    max-width: 60%;
    display: inline-block;
  }

}