.about-person{
  margin-bottom: 50px;
  & .data-holder{

  }

  .title-block{
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 40px;
    @media(max-width: 767px){
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    &:before{
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      height: 5px; width: 100px;
      background-color: $color1;
    }
    & h2{
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 400;
      @media(max-width: 767px){
        font-size: 21px;
      }
      @media(max-width: 480px){
        margin-top: 20px;
      }
    }
    & h3{
      display: block;
      font-family: $font-family2;
      font-weight: 300;
      font-size: 12px;
      color: $color1;
      margin-top: 10px;
      @media(max-width: 767px){
        margin-top: 5px;
      }
    }
  }

  & .description{
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
  }

  & .social-links{
    margin: 30px 0;
    & a{
      display: inline-block;
      width: 40px; height: 40px;
      border: 1px solid $color1;
      color: $color1;
      font-size: 18px;
      line-height: 38px;
      text-align: center;
      margin-right: 16px;
      &:hover{
        background-color: $color1;
        color: $white;
      }
    }
  }
}

@media(max-width: 480px){
  .child-block-on-480 > div{
    width: 100%;
  }
}