// ***********************************
// TABS
// ***********************************
.tab-pane {display: none!important;}
.tab-pane.active {display: block!important;}

/* Tabs */
.tabs-panel{
  border: 0 none;
}
.tabs-panel .nav-tabs{
  border: 0 none;
  width: 100%;
  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  @media(max-width: 600px){
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
  }
}
.tabs-panel .nav-tabs > li{
  display: inline-block;
  position: relative;
  width: 170px;
  margin: 0 15px;
  float: none;
  @media(min-width: 601px) and (max-width: 767px){
    margin: 0 5px;
  }
  @media(max-width: 600px){
    margin: 0;
    width: 100%;
    margin-left: -15px; margin-right: -15px;
    border-bottom: 1px solid #cfcfcf;
  }
}
.tabs-panel .nav-tabs > li.active > a,
.tabs-panel .nav-tabs > li.active > a:hover,
.tabs-panel .nav-tabs > li.active > a:focus{
  border: 0 none;
  background-color: $yellow;
  color: $black;
}
.tabs-panel .nav-tabs > li > a{
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 25px;
  padding: 15px 5px;
  background-color: $black;
  color: $white;
  display: block;
  width: 100%;
  border-radius: 0;
  border: 0 none;
  text-align: center;
  @include transition-all(0.4s);
}
.tabs-panel .nav-tabs > li > a:hover,
.tabs-panel .nav-tabs > li > a:focus{
  color: $black;
  background-color: $yellow;
}