// ***********************************
// MIXINS
// ***********************************

@mixin transition-all($time:1s){
	-webkit-transition: all $time ease-in-out;
	-moz-transition: all $time ease-in-out;
	-ms-transition: all $time ease-in-out;
	-o-transition: all $time ease-in-out;
	transition: all $time ease-in-out;
}

@mixin fade($time:0.5s){
	-webkit-transition: background-color $time ease-in-out;
	-moz-transition: background-color $time ease-in-out;
	-o-transition: background-color $time ease-in-out;
	-ms-transition: background-color $time ease-in-out;
	transition: background-color $time ease-in-out;
}
// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

//placeholder
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}