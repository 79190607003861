.contact{
  & form{
    margin-top: 30px;
  }
  & .text-editor{
    h2{
      font-size: 24px;
      position: relative;
      padding-bottom: 25px;
      text-transform: uppercase;
      &:before{
        content: '';
        position: absolute;
        left: 0; bottom: 0;
        height: 5px; width: 100px;
        background-color: $color1;
      }
    }
    p{
      font-family: $font-family2;
      font-weight: 300;
      font-size: 16px;
    }
  }

  & .box-border{
    border: 1px solid $color1;
    padding: 0 10px 30px 10px;
  }

  & .date-input:before{
    content: "\f274";
    font-family: FontAwesome;
    font-size: 24px;
    color: $color1;
    position: absolute;
    right: 10px; top: -5px;
  }

  & .fake-checkbox{
    margin-top: 30px;
  }
  & .btn-ghost{
    padding: 9px 15px;
  }

  & .chapta_box{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & .captcha-holder{
    position: relative;
    padding-left: 90px;
    & img{
      height: 30px;
      position: absolute;
      left: 0; top: 0;
    }
  }
  & .captcha-text{
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 0;
    margin-top: 10px;
  }
}