#header{
  border-bottom: 5px solid $color1;
  position: relative;
  z-index: 2;

  & #logo{
    display: block;
    width: 100%;
    margin-top: 25px;
    & p{
      margin: 0;
    }
  }

  & .header-top{
    position: relative;
    height: 90px;
    & .header-options{
      height: 40px;
      padding-right: 50px;
      border: 1px solid $black;
      position: absolute;
      right: 0; top: 0;
      & #language_button{
        width: 100px;
        & li{
          list-style: none;
          float: left;
          display: inline-block;
          width: 50px;
          text-transform: uppercase;
          text-align: center;
          & a{
            display: block;
            line-height: 20px; padding: 9px 0;
          }
          &:nth-of-type(1){
            position: relative;
            &:before{
              content: '';
              position: absolute;
              right: 0; top: 10px;
              width: 1px; height: 20px;
              background-color: $black;
            }
          }
          &.active a{
            color: $color1;
          }
        }
      }
    }
  }

}

.fixed-header{
  margin-top: 160px;
  #header{
    background-color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    box-shadow: 0 0 2px #aaa;
    height: 95px;



    & #logo{
      display: block;
      width: 100%;
      height: 90px; padding: 10px 0;
      margin-top: 0;
      & img{
        max-height: 70px;
        max-width: 100%;
        height: auto; width: auto;
      }
    }

    & .header-top{
      height: 50px;
    }
  }
}
@media(max-width: 991px){
  #header{
    background-color: $white;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 4;
    height: 95px;



    & #logo{
      display: block;
      width: 100%;
      height: 90px; padding: 10px 0;
      margin-top: 0;
      & img{
        max-height: 70px;
        max-width: 100%;
        height: auto; width: auto;
      }
    }

    & .header-top{
      height: 51px;
    }
  }

  .fixed-header{
    margin-top: 95px;
    #header{
      position: fixed;
      -webkit-animation-duration: 600ms;
      animation-duration: 600ms;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
      box-shadow: 0 0 2px #aaa;
      height: 55px;
      & #logo{
        height: 50px;
        padding: 5px 0 0 0;
        & img{
          max-height: 40px;
        }
      }
      & .header-top{
        display: none;
      }
    }
    & .main-nav-trigger{
      margin-top: 10px;
    }
  }
}


.resp-menu-container,
.main-nav-trigger{
  display: none;
}

@media(max-width: 991px){
  .main-nav-trigger{
    display: inline-block;
    float: right;
    width: 50px; height: 40px;
    position: relative;
    cursor: pointer;
    background-color: $color1;
    color: $white;
    font-size: 18px; text-align: center;
    @include transition-all(0.4s);
    &:hover{
      color: $black
    }
    & span{
      line-height: 40px;
      text-align: center;
      display: block;
      font-size: 18px;
    }
  }
  .openMenu .main-nav-trigger{
    color: $black;
  }
  .openMenu header{
    position: relative;
    z-index: 100!important;
  }
  .openMenu .resp-menu-container{
    right: 0;
  }
  & .close-menu{
    color: $white;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;
    width: 40px; height: 40px;
    text-align: center; line-height: 40px;
  }
  .resp-menu-container{
    display: block;
    padding-top: 60px;
    background-color: $color1;
    height: 100%;
    right: -100%;
    margin: 0;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 99999;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    @media(max-width: 480px){
      min-width: 1px;
      width: 100%;
    }
    & li{
      position: relative;
      text-align: center;
      text-transform: uppercase;
      &:hover{
        & > a{
          color: $black;
        }
        & > ul{
          display: block;
        }
      }
      & a{
        text-align: left;
        color: $white;
        padding: 10px 0px 10px 20px;
        width: 100%;
        display: block;
        line-height: 20px;
        position: relative;
        & :hover{
          color: $black;
        }
      }
      &.submenu{
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: 20px; left: 5px;
          width: 0; height: 0;
          border-style: solid;
          border-width: 4px 4px 0 4px;
          border-color: $white transparent transparent transparent;
        }
      }
    }
    /*I level*/
    & > ul{
      margin: 0;
      padding: 0 10px 20px 10px;
      & > li{
        border-top: 2px solid $white;
        &:first-of-type{
          border: 0;
        }
      }
    }
    /*II level*/
    & li ul{
      display: none;
      position: relative;
      top: 0;
      clear: both;
    }
    & ul ul{
      border-top: 2px solid $white;
      padding-left: 10px;
    }
  }
}


