.dest-tour-page{
  & .title-block{
    position: relative;
    padding-bottom: 50px;
    margin-bottom: 20px;
    &:before{
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      height: 5px; width: 100px;
      background-color: $color1;
    }
    & h1{
      font-size: 36px;
      font-weight: 400;
    }
    & .location{
      display: block;
      font-family: $font-family2;
      font-size: 18px;
      color: $color1;
      margin-top: 10px;
    }
  }
  & .large-btn{
    padding: 9px 15px;
    text-transform: uppercase;
    margin-top: 50px;
    cursor: pointer;
  }
  & .description{
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
  }
}

.additional-info{
  & .block{
    margin-bottom: 70px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: -30px; left: 0;
      height: 2px; width: 100%;
      background: url('../images/divider-dotted.png') repeat-x left;
    }

    & h3{
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 20px;
      & i{
        font-size: 24px;
        line-height: 30px;
        display: inline-block;
        margin-right: 5px;
        vertical-align: top;
        color: #4B3927;
      }
      & .activity{
        font-family: $font-family2;
        font-weight: 300;
        font-size: 12px;
      }
    }
    & .block-desc{
      font-family: $font-family2;
      font-weight: 300;
      font-size: 16px;
    }
    & .image-holder{
      text-align: right;
    }
  }
  & .large-btn-holder{
    margin: 0 0 50px 0;
    & .large-btn{
      margin-top: 0;
    }
  }
}


// kao owl type 3
.destinations-preview{
  margin-top: 40px;
  & .content-holder{
    position: relative;
  }
  & .image-holder{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    & img{
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      @include transition-all(0.4s);
    }
    & .overlay{
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      background-color: rgba(85,85,85,0.3);
      cursor: pointer;
      &:before,
      &:after {
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        content: '';
        opacity: 0;
        -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
        transition: opacity 0.4s, transform 0.4s;
      }
      &:before{
        border-top: 1px solid $color1;
        border-bottom: 1px solid $color1;
        -webkit-transform: scale(0,1);
        transform: scale(0,1);
      }
      &:after{
        border-right: 1px solid $color1;
        border-left: 1px solid $color1;
        -webkit-transform: scale(1,0);
        transform: scale(1,0);
      }
    }
    &:hover{
      & img{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
      & .overlay{
        &:before,
        &:after{
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  }

  & .content-holder{
    position: relative;
    padding: 0 1px;
  }
  & .image-holder{
    position: relative;
    overflow: hidden;
  }

  & .text-holder{
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    padding: 0 40px 15px 20px;
    color: $white;
    pointer-events: none;
    & .title{
      font-size: 18px;
      text-transform: uppercase;
      margin: 0;
    }
    & .plus-sign{
      position: absolute;
      right: 20px; bottom: 15px;
      font-size: 24px;
    }
  }
}

// kao owl type 2
.tours-preview{

  & .content-holder{
    position: relative;
    padding: 0 1px;
  }
  & .image-holder{
    position: relative;
    overflow: hidden;
    & img{
      -webkit-backface-visibility: hidden;
      @include transform(translate(-50%, -50%) scale(1.0) translateZ(0));
      @include transition-all(0.4s);
    }
  }

  & .text-holder{
    text-align: center;
    position: relative;
    border: 1px solid $grey;
    border-top: 0 none;
    padding: 15px 10px;

    & .title{
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 5px;
    }
    & .tour-desc{
      font-family: $font-family2;
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    & .read-more{
      display: inline-block;
    }
  }

  & .content-holder:hover{
    & .read-more{
      background-color: $color1;
      color: $white;
    }
    & .image-holder img{
      @include transform(translate(-50%, -50%) scale(1.1) translateZ(0));
      @include transition-all(0.4s);
    }
  }

}


//tours category
.tours-main{
  background-color: $grey;
  margin-bottom: 20px;
  & .image-holder,
  & .text-holder{
    height: 450px;
    @media(min-width: 768px) and (max-width: 991px){
      height: 350px;
    }
  }
  & .image-holder{
    @media(min-width: 768px) and(max-width: 991px){
      margin-right: -10px;
    }
    @media(max-width: 767px){
      height: 300px;
    }
  }
  & .text-holder{
    text-align: center;
    padding: 30px 20px 30px 20px;
    background-color: rgba(255,255,255,0.9);
    & p{
      font-family: $font-family2;
      font-weight: 300;
      font-size: 16px;
    }
    @media(max-width: 767px){
      height: auto;
      padding: 20px 10px 20px 10px;
    }
  }

  & .title-block{
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media(max-width: 767px){
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    &:before{
      content: '';
      position: absolute;
      left: 50%; bottom: 0;
      height: 5px; width: 100px;
      margin-left: -50px;
      background-color: $color1;
    }
    & h2{
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 400;
      @media(max-width: 767px){
        font-size: 21px;
      }
    }
    & .location{
      text-transform: uppercase;
      display: block;
      font-family: $font-family2;
      font-size: 18px;
      color: $color1;
      margin-top: 10px;
      @media(max-width: 767px){
        font-size: 16px;
        margin-top: 5px;
      }
    }
  }
  & .read-more{
    padding: 9px 15px;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
    width: 170px;
    @media(min-width: 768px){
      position: absolute;
      bottom: 30px; left: 50%;
      margin-left: -85px;
    }
  }
}