#footer{
  background-color: #4B3A28;
  padding: 30px 0;
  @media(max-width: 991px){
    padding: 15px 0;
  }
}

.foo-text{
  display: table;
  width: 100%;
  height: 50px;
  & p{
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    font-family: $font-family2;
    font-weight: 300;
    color: $white;
    & a{
      color: $color1;
    }
  }
  @media(max-width: 991px){
    text-align: center;
  }
}
.foo-links{
  display: inline-block;
  float: right;
  & > li{
    display: inline-block;
    float: left;
    margin-left: 10px;
    &:nth-of-type(1){
      margin: 0;
    }
    & a{
      display: block;
      width: 50px; height: 50px;
      border: 1px solid $color1;
      text-align: center;
      color: $color1;
      line-height: 48px;
      font-size: 24px;
      cursor: pointer;
      &:hover{
        background-color: $color1;
        color: #4B3A28;
      }
    }
  }
  @media(max-width: 991px){
    float: none;
    display: block;
    width: 230px;
    margin: 0 auto;
  }
}