.newsletter-signup{
  background-color: $white;

  .subscribe-desc{
    max-width: 100%;
    width: 600px;
    margin: 20px auto 40px auto;
    text-align: center;
    font-family: $font-family2;
    font-weight: 300;

  }

  & form{
    max-width: 100%;
    & .signup-line{
      position: relative;
      padding-right: 250px;
      @media(max-width: 600px){
        padding-right: 0;
      }
    }
    & input.large-input{
      line-height: 28px;
      padding: 25px 25px;
      border: 1px solid $grey;
      width: 100%;
      margin-top: 0px;
      background-color: $white;
      border-radius: 0px;
      color: $black;
      font-size: 24px;
      &::-webkit-input-placeholder {
        color: black;
      }

      &:-moz-placeholder { /* Firefox 18- */
        color: black;
      }

      &::-moz-placeholder {  /* Firefox 19+ */
        color: black;
      }

      &:-ms-input-placeholder {
        color: black;
      }
      @include placeholder {
        color: $grey;
      }
      @media(max-width: 600px){
        font-size: 16px;
        padding: 10px 10px;
      }
    }
    & #nl-submit{
      position: absolute;
      right: 0; top: 0;
      background: $color1;
      color: $white;
      width: 250px;
      text-align: center;
      border-radius: 0px;
      border: 1px solid $color1;
      font-size: 24px;
      line-height: 28px;
      padding: 25px 10px;
      margin-top: 0;
      @media(max-width: 600px){
        position: relative;
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        padding: 10px 10px;
      }
    }
  }

  & .chapta_box{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & .captcha-holder{
    position: relative;
    padding-right: 250px;
    & img{
      height: 50px;
      position: absolute;
      right: 50px; top: 15px;
    }
  }
  @media(max-width: 600px){
    & .captcha-holder{
      padding-right: 120px;
      & img{
        height: 40px;
        position: absolute;
        right: 0px; top: 5px;
      }
    }
  }


  #newsleter_form_error p{
    text-align: right;
  }
}


.newsletter-content-holder{
  display: block;
  margin: 100px auto;
  max-width: 800px;
  text-align: center;
  & h1{
    font-weight: 700;
    font-size: 48px;
    color: $color1;
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 40px;
    &:before{
      content: '';
      position: absolute;
      bottom: 0; left: 50%;
      margin-left: -50px;
      width: 100px; height: 5px;
      background-color: $color1;
    }
    & span{
      margin-top: 15px;
      display: block;
      font-size: 36px;
      font-weight: 400;
      color: $black;
    }
  }
  & .additional-text{
    h2{
      font-weight: 400;
      font-size: 24px;
      color: $color1;
      margin-bottom: 15px;
    }
    p{
      font-weight: 400;
      font-size: 24px;
      margin: 0;
    }
  }

  @media(max-width: 767px){
    display: block;
    margin: 50px auto;
    max-width: 600px;
    text-align: center;
    & h1{
      font-size: 32px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      &:before{
        height: 3px;
      }
      & span{
        margin-top: 10px;
        font-size: 22px;
      }
    }
    & .additional-text{
      h2{
        font-size: 16px;
        margin-bottom: 10px;
      }
      p{
        font-size: 16px;
      }
    }
  }
}

