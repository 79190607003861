body{
  color: $black;
  font-family: $font-family1;
  font-size: 14px;
  line-height: 1.2;
  overflow-x: hidden;
}

@media (max-width: 767px){
  body .container{
    padding-left: 10px;
    padding-right: 10px;
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

::selection {
  background: $color1; /* WebKit/Blink Browsers */
  color: $white!important;
}
::-moz-selection {
  background: $color1; /* Gecko Browsers */
  color: $white!important;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}
a:focus, a:active,
button,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: none !important;
}
a{
  color: $black;
  outline: none;
}


a:hover, a:active, a:focus, a:visited {
  @include transition-all(.4s);
  text-decoration: none;
  color: inherit;
}
a.underline:hover, a.underline:active, a.underline:focus, a.underline:visited {
  text-decoration: underline;
}

ul{
  margin: 0;
  padding: 0;
}
ul li{
  list-style-type: none;
}
h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
  font-weight: 400;
}
.sp-body{
  overflow: hidden;
}

img {
  height: auto;
  max-width: 100%;
}

/*js-helper*/
#js_width-helper{width: 1px}
@media(min-width: 768px) and (max-width: 1200px){
  #js_width-helper{width: 768px;}
}
@media(min-width: 1201px) and (max-width: 1440px){
  #js_width-helper{width: 1201px;}
}
@media(min-width: 1441px) and (max-width: 1600px){
  #js_width-helper{width: 1441px;}
}
@media(min-width: 1601px){
  #js_width-helper{width: 1601px;}
}


.color1,
a.color1{
  color: $yellow;
}

// custom classes
.relative{position: relative}
.inl-bl{display: inline-block;}
.fl-left{float: left;}
.fl-right{float: right;}
.fl-none{float: none;}
.mar-t{
  margin-top: 15px;
}
.uppercase{text-transform: uppercase;}
.underline{text-decoration: underline;}
.clear{clear: both;}
.center{text-align: center;}
.divider-top{
  margin-top: 40px;
}

.full-width-all{width: 100%;}
.transition{
  @include transition-all(0.4s);
}

.mar-t-on-small{
  @media(max-width: 991px){
    margin-top: 30px;
  }
}
.child-block-on-480{
  @media(max-width: 480px){
    & > div{
      width: 100%;
    }
  }
}
.block-on-480{
  @media(max-width: 480px){
    width: 100%;
  }
}

.ratio1-1{
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.ratio2-3{
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 66.6667%;
  }
}

.ratio10-8{
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 125%;
  }
}

.invisible-img{
  width: 100%;
  height: 100%;
  opacity: 0;
}
.cover-img{
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: auto;
  height: auto;
  max-width: 9999px;
  max-height: 9999px;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &.align-width{
    width:100%; height: auto!important;
  }
  &.align-height{
    height:100%; width: auto!important;
  }
}
.cover-bg{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.full-width-helper{
  padding: 0 50px;
  @media(max-width: 767px){
    padding: 0;
  }
}

h1.page-title{
  font-size: 24px;
  position: relative;
  font-weight: 400;
  padding-bottom: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  &:before{
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    width: 100px; height: 5px;
    background-color: $color1;
    text-transform: uppercase;
  }
}
.page-description{
  font-family: $font-family2;
  font-weight: 300;
  font-size: 16px;
}

h2.section-title{
  font-size: 36px;
  display: block;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  &.line-behind:before{
    content: '';
    position: absolute;
    width: 800px; max-width: 100%;
    height: 1px;
    background-color: $color1;
    left: 50%; top: 50%;
    @include translate(-50%, -50%);
  }
  & span{
    display: inline-block;
    padding: 0 30px;
    background-color: $white;
    position: relative;
    z-index: 1;
  }
  @media(max-width: 767px){
    font-size: 21px;
    & span{
      padding: 0 10px;
    }
  }
}
.link-bellow-title.btn-ghost{
  width: 150px;
  padding: 9px 10px;
  text-transform: uppercase;
  display: block;
  margin: 20px auto 40px auto;
  color: $color1;
  @media(max-width: 767px){
    margin: 10px auto 20px auto;
  }
}

.default-section{
  padding: 90px 0 70px 0;
  @media(max-width: 767px){
    padding: 50px 0 30px 0;
  }
}

.divider-top{
  margin-bottom: 40px;
}