@media(min-width: 992px){
  #main-nav {
    display: block;
    text-align: right;
    & > ul {
      display: inline-block;
      float: right;
      & li{
        position: relative;
      }
      & > li {
        float: left;
        & > a {
          color: $black;
          text-transform: uppercase;
          display: block;
          padding: 10px 10px 40px 10px;
          line-height: 15px;
          & span{
            display: block;
            position: relative;
          }
        }
        &.submenu{
          & > a span{
            &:before{
              content: '';
              position: absolute;
              bottom: -5px; left: 0;
              width: 100%; height: 3px;
              background-color: $color1;
            }
            &:after{
              content: '';
              position: absolute;
              bottom: -9px; left: 50%; margin-left: -4px;
              width: 0; height: 0;
              border-style: solid;
              border-width: 4px 4px 0 4px;
              border-color: $color1 transparent transparent transparent;
            }
          }
          &:hover{
            & > a span:after{
              display: none;
            }
          }
        }
      }

      //UL - level 2
      & > li > ul{
        padding: 0 5px 15px 5px;
        min-width: 100%;
        position: absolute;
        top: 100%;
        left: 0%;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        transition: 400ms ease;
        background-color: $white;
        white-space: nowrap;
        border-top: none;

        & li {
          display: block;
          text-align: center;
          border-bottom: 1px solid $grey;
          padding-top: 10px;
          & a {
            display: block;
            line-height: 20px;
            padding: 0 10px;
          }
        }

      }

      //UL - level 3
      & > li > ul li ul{
        padding: 0 5px 15px 5px;
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        filter: alpha(opacity=0);
        transition: 400ms ease;
        background-color: $white;
        white-space: nowrap;
        border-top: none;
      }


      & li:hover{
        & > a{
          color: $color1;
        }
        & > ul{
          visibility: visible;
          opacity: 1;
          filter: alpha(opacity=100);
        }
      }

    }
  }

  .fixed-header{
    #main-nav {
      display: block;
      text-align: right;
      & > ul > li > a {
        padding: 10px 10px 15px 10px;
      }
    }

  }
}