//ovaj deo koda ide uvek zbog bagova u samom pluginu
.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}
.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
}




.owl-carousel.type1{
  .owl-nav{
    position: absolute;
    width: 100%;
    top: 50%; left: 0;
    height: 0;
    & > div{
      position: relative;
      display: inline-block;
      @include translate(0, -50%);
      &.owl-next{
        float: right;
      }
    }
  }

  & .custom-arr{
    display: inline-block;
    width: 70px; height: 120px;
    background-image: url('../images/custom-arr-t1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    opacity: 0.6;
    @include transition-all(0.4s);
    &.right{
      background-position: right center;
    }
    &:hover{
      opacity: 0.9;
    }
    @media(max-width: 767px){
      width: 35px; height: 60px;
    }
  }

  & .content-holder{
    position: relative;
  }
  & .image-holder{
    height: 450px;
    position: relative;
    overflow: hidden;
    & .overlay{
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      background-color: rgba(17,17,17,0.4);
    }
  }

  & .text-holder{
    position: absolute;
    top: 360px; left: 0;
    width: 100%;
    text-align: center;
    color: $white;
    @include transition-all(0.6s);
    & .title{
      text-transform: uppercase;
      font-size: 100px;
      font-weight: 700;
      opacity: 0.7;
    }
    & .desc{
      font-family: $font-family2;
      font-size: 16px;
      max-width: 90%;
      display: inline-block;
      margin-bottom: 40px;
    }
    & .read-more{
      text-transform: uppercase;
      display: inline-block;
      width: 140px; height: 40px;
      border: 1px solid $white;
      line-height: 38px;
      color: $white;
      &:hover{
        background-color: $white;
        color: $black;
      }
    }
  }

  & .content-holder:hover .text-holder{
    top: 50%;
    @include translate(0, -50%);
  }

  @media(min-width: 768px) and (max-width: 1199px){
    & .image-holder{
      height: 350px;
    }
    & .text-holder{
      top: 275px;
      & .title{
        font-size: 80px;
      }
      & .desc{
        font-size: 14px;
        margin-bottom: 30px;
      }
    }
  }
  @media(max-width: 767px) {
    & .image-holder{
      height: 300px;
    }
    & .text-holder{
      top: 245px;
      & .title{
        font-size: 60px;
      }
      & .desc{
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }

}

.owl-carousel.type2{
  .owl-nav{
    position: absolute;
    width: 100%; height: 0;
    top: 0; left: 0;
    & > div{
      position: relative;
      display: inline-block;
      @include translate(0, -50%);
      &.owl-next{
        float: right;
      }
    }
  }

  & .custom-arr{
    display: inline-block;
    width: 70px; height: 120px;
    background-image: url('../images/custom-arr-t1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    opacity: 0.6;
    @include transition-all(0.4s);
    &.right{
      background-position: right center;
    }
    &:hover{
      opacity: 0.9;
    }
    @media(max-width: 767px){
      width: 35px; height: 60px;
    }
  }

  & .content-holder{
    position: relative;
    padding: 0 1px;
  }
  & .image-holder{
    position: relative;
    overflow: hidden;
    & img{
      -webkit-backface-visibility: hidden;
      @include transform(translate(-50%, -50%) scale(1.0) translateZ(0));
      @include transition-all(0.4s);
    }
  }

  & .text-holder{
    text-align: center;
    position: relative;
    border: 1px solid $grey;
    border-top: 0 none;
    padding: 15px 10px;

    & .title{
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 5px;
    }
    & .desc{
      font-family: $font-family2;
      font-size: 16px;
      font-weight: 300;
    }
    & .read-more{
      display: inline-block;
    }
  }

  & .content-holder:hover{
    & .read-more{
      background-color: $color1;
      color: $white;
    }
    & .image-holder img{
      @include transform(translate(-50%, -50%) scale(1.1) translateZ(0));
      @include transition-all(0.4s);
    }
  }

}

.owl-carousel.type3{
  @media(min-width: 1199px){
    box-sizing: content-box;
    max-width: 1140px;
    margin: 0 auto;
  }
  @media(min-width: 1441px){
    padding: 0 100px;
  }
  .owl-nav{
    position: absolute;
    width: 100%; height: 0;
    top: 50%; left: 0;
    & > div{
      position: relative;
      display: inline-block;
      @include translate(0, -50%);
      &.owl-next{
        float: right;
      }
    }
  }

  & .custom-arr{
    display: inline-block;
    width: 70px; height: 120px;
    background-image: url('../images/custom-arr-t1.png');
    @media(min-width: 1441px){
      background-image: url('../images/custom-arr.png');
    }
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    opacity: 0.6;
    @include transition-all(0.4s);
    &.right{
      background-position: right center;
    }
    &:hover{
      opacity: 0.9;
    }
    @media(max-width: 767px){
      width: 35px; height: 60px;
    }
  }

  & .content-holder{
    position: relative;
  }
  & .image-holder{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    & img{
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
//      @include transition-all(0.4s);
    }
    & .overlay{
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      background-color: rgba(85,85,85,0.3);
      cursor: pointer;
      &:before,
      &:after {
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        content: '';
        opacity: 0;
        -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
        transition: opacity 0.4s, transform 0.4s;
      }
      &:before{
        border-top: 1px solid $color1;
        border-bottom: 1px solid $color1;
        -webkit-transform: scale(0,1);
        transform: scale(0,1);
      }
      &:after{
        border-right: 1px solid $color1;
        border-left: 1px solid $color1;
        -webkit-transform: scale(1,0);
        transform: scale(1,0);
      }
    }
    &:hover{
      & img{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
      & .overlay{
        &:before,
        &:after{
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  }

  & .content-holder{
    position: relative;
    padding: 0 1px;
  }
  & .image-holder{
    position: relative;
    overflow: hidden;
  }

  & .text-holder{
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    padding: 0 40px 15px 20px;
    color: $white;
    pointer-events: none;
    & .title{
      font-size: 18px;
      text-transform: uppercase;
      margin: 0;
    }
    & .plus-sign{
      position: absolute;
      right: 20px; bottom: 15px;
      font-size: 24px;
    }
  }

  & .content-holder:hover{

  }

}

.owl-carousel.type4{
  & .owl-dots{
    text-align: center;
    margin: 15px 0;
  }
  & .owl-dot{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid $color1;
    margin: 0 5px;
    &.active{
      background-color: $color1;
    }
  }
}



.bx-controls-direction{
  text-align: center;
  margin-top: 20px;
  & > a{
    width: 60px; height: 35px;
    display: inline-block;
    background-image: url('../images/custom-arr-bx.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    opacity: 0.6;
    &:last-of-type{
      background-position: right center;
    }
    &:hover{
      opacity: 0.9;
    }
  }
  @media(max-width: 767px){
    height: 60px; margin-top: 10px;
    & > a{
      height: 60px; width: 35px;
      background-image: url('../images/custom-arr-bx-horizontal.png');
      float: left;
      position: relative;
      top: 70px;
      &:last-of-type{
        float: right;
      }
    }
  }
}
@media(max-width: 767px){
  .bx-wrapper{
    position: relative;
  }
  .bx-controls{
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 0;
  }
}
