.error-container{
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 1000;
  background-image: url("../images/error-img.jpg");
  text-align: center;

  & .logo{
    display: inline-block;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-top: 50px;
    & p{
      margin: 0;
    }
  }

  & .positioning-middle{
    position: absolute;
    width: 100%;
    top: 50%; left: 50%;
    @include translate(-50%, -50%);
  }

  & h2{
    font-family: $font-family2;
    font-weight: 400;
    font-size: 64px;
    color: $white;
    margin-bottom: 20px;
  }
  & .btn-back{
    font-family: $font-family2;
    font-size: 24px;
    border: 2px solid $color1;
    background-color: $color1;
    color: $white;
    display: inline-block;
    line-height: 36px;
    padding: 20px 30px;
    &:hover{
    }
  }

  & span.error-404{
    color: $white;
    display: inline-block;
    position: absolute;
    bottom: 50px;
    left: 50%;
    @include translate(-50%, 0);
    font-family: $font-family2;
    font-size: 36px;
    line-height: 40px;
    padding: 30px;
    background-color: rgba(17, 17, 17, 0.4);
  }

  @media(max-width: 767px){

    & .logo{
      padding: 15px;
      margin-top: 30px;
      & img{
        max-height: 50px;
        width: auto;
      }
    }

    & h2{
      font-size: 36px;
      margin-bottom: 15px;
    }
    & .btn-back{
      font-size: 18px;
      line-height: 26px;
      padding: 15px 20px;
    }

    & span.error-404{
      bottom: 30px;
      font-size: 26px;
      line-height: 30px;
      padding: 15px 20px;
    }
  }

}