/* Default custom select styles */
.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: none; /* For better accessibility add a style for this in your skin */
  }

  & select {
    display: none;
  }

  & span {
    display: block;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }

  & > span { /* Placeholder and selected option */
    line-height: 20px;
  }

  & > span::after, .cs-selected span::after{
    position: absolute;
    line-height: 20px; text-align: center;
    top: 50%; right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-font-smoothing: antialiased;
    height: 20px; width: 20px;
    -moz-osx-font-smoothing: grayscale;
    font-family: "FontAwesome";
  }

  & .cs-selected span::after{
    background-color: transparent;
    content: '\2713';
  }

  & > span::after {
    content: "\f107";
    font-size: 20px;
  }

  &.cs-active {

    & > span::after {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }
  }

}



/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: $white;
  visibility: hidden;
  top: 40px;
  z-index: 3;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
  & ul > li:first-of-type{
    display: none;
  }
}

.cs-select ul {
  list-style: none;
  margin: 0;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  max-height: 200px;
  overflow: auto;
}

.cs-select ul span:hover{
  color: $black;
}

.cs-select ul li.cs-focus span {

}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {

}

.cs-select li.cs-optgroup > span {
  cursor: default;
}


// type 1 - koristi se za contact stranu...(kao input polje)
.type1.cs-select{
  & > span{
    display: block;
    width: 100%;
    border: 1px solid $grey;
    border-width: 0 0 1px 0;
    line-height: 20px;
    padding: 4px 10px;
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
    margin-top: 10px;
    &:after{
      right: 15px;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 7.5px 0 7.5px;
      border-color: $color1 transparent transparent transparent;
    }
  }

  & .cs-options {
    top: 38px;
    border: 1px solid $color1;
    ul{
      padding-left: 10px; padding-right: 10px;
      border: 0 none;
    }
    & li{
      border-bottom: 1px solid $grey;
      line-height: 20px;
      font-family: $font-family2;
      font-weight: 300;
      padding: 5px 10px;
      &:hover span{
        color: $color1;
      }
      &.cs-selected{
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

// type 2 - koristi se za filtriranje proizvoda...
.type2.cs-select{
  font-size: 14px;
  & > span{
    color: $black;
    padding: 9px 10px;
  }
  & > span::after,
  & .cs-selected span::after{
    right: 10px;
    font-size: 26px;
  }
  & .cs-options {
    top: 38px;
    & ul {
      border: 0;
      padding: 10px 10px 5px 10px;
      & li{
        padding-bottom: 5px;
        & span{
          color: #71747A;
          &:hover{
            color: $black;
          }
        }
      }
    }
    & .cs-selected span{
      color: $black;
      cursor: default;
      &:after{
        display: none;
      }
    }
  }
}

// type 3 - koristi se za filtriranje proizvoda...
.type3.cs-select{
  font-size: 14px;
  & > span{
    color: $black;
    padding: 9px 10px;
  }
  & > span::after,
  & .cs-selected span::after{
    right: 10px;
    font-size: 26px;
  }
  & .cs-options {
    top: 38px;
    & ul {
      border: 0;
      padding: 10px 10px 5px 10px;
      & li{
        padding-bottom: 5px;
        & span{
          color: #71747A;
          &:hover{
            color: $black;
          }
        }
      }
    }
    & .cs-selected span{
      color: $black;
      cursor: default;
      &:after{
        display: none;
      }
    }
  }
}
