.simple-page-top{
  & .title-block{
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media(max-width: 767px){
      padding-bottom: 10px;
      margin-bottom: 20px; margin-top: 20px;
    }
    &:before{
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      height: 5px; width: 100px;
      background-color: $color1;
    }
    & h2{
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 400;
      @media(max-width: 767px){
        font-size: 21px;
      }
    }
    & .subtitle{
      text-transform: uppercase;
      display: block;
      font-family: $font-family2;
      font-size: 18px;
      color: $color1;
      margin-top: 10px;
      @media(max-width: 767px){
        font-size: 16px;
        margin-top: 5px;
      }
    }
  }
  & .description{
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
  }
  & .large-btn{
    padding: 9px 20px;
    margin-top: 20px;
  }
}
.simple-page-bottom{
  margin: 40px 0 70px 0;
}


.text-editor{
  line-height: 1.4;
  & h1{
  }

  & h2{
    font-size: 18px;
    font-weight: 400;
  }

  & h3{
    font-size: 16px;
    font-weight: 400;
  }

  & p{
    font-family: $font-family2;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0;
  }

  & a{
    text-decoration: underline;
    color: $color1;
    &:hover{
      text-decoration: underline;
      color: $color1
    }
  }
  &
  ul{
    font-family: $font-family2;
    font-weight: 300;
    padding-top:5px;
    font-size: 16px;
    padding-left: 20px;

    & li{
      position: relative;
      margin-bottom: 5px;
      &:before{
        content: "";
        position: absolute;
        top: 5px; left: -20px;
        width: 10px; height: 10px;
        background-color: $color1;
      }
    }
  }

  & ol{
    padding-top:5px;
    padding-left: 30px;

    & li{
      position: relative;
      margin-bottom: 5px;
    }
  }

  & blockquote{
    padding: 50px 50px 50px 120px;
    margin-bottom: 0;
    position: relative;
    border: 0;
    & p{
      font-family: $font-family2;
      font-weight: 300;
      font-style: italic;
      font-size: 32px;
    }
    &:before{
      content: '';
      position: absolute;
      height: 100%; width: 5000px;
      left: -2000px; top: 0;
      background-color: $grey;
      z-index: -1;
    }
    &:after{
      content: "\f10d";
      font-family: FontAwesome;
      font-size: 48px;
      color: #aaa;
      position: absolute;
      left: 30px; top: 50px;
    }
    @media(max-width: 767px){
      padding: 10px 15px;
    }
  }

  & a.btn-link{
    background-color: transparent;
    border: 1px solid $color1;
    border-radius: 0;
    text-align: center;
    color: $color1;
    line-height: 20px;
    padding: 9px 20px;
    display: inline-block;
    text-decoration: none;
    @include transition-all(0.4s);
    &:hover{
      background-color: $color1;
      color: $white;
      text-decoration: none;
    }
  }

}

